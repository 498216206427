





























































































































































































































































































































import { defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { ExecutionType } from '../constants';

import { LockWidget, ValidationWidget, RunWidget, ExportWorkflowWidget, RunEdgeWidget } from './menu';
import { renamings } from '@/app/utilities';

export default defineComponent({
    name: 'WorkflowMenu',
    props: {
        activeDropdown: {
            type: String,
            default: null,
        },
        disableClickEvent: {
            type: Boolean,
            default: false,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        isFinalised: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        canBeReopened: {
            type: Boolean,
            default: false,
        },
        isOnPremise: {
            type: Boolean,
            default: false,
        },
        runningEdgeExecution: {
            type: Boolean,
            default: false,
        },
        downloading: {
            type: Boolean,
            default: false,
        },
    },
    components: { ValidationWidget, LockWidget, RunWidget, ExportWorkflowWidget, RunEdgeWidget },
    setup(props, { emit }) {
        const activePage = ref('graph-view');

        const openPage = (page: string) => {
            if (!props.disableClickEvent) {
                emit('open', page);
                activePage.value = page;
            }
        };

        const toggleDropdown = (dropdown: string) => {
            if (!props.disableClickEvent) {
                emit('toggle-dropdown', dropdown);
            }
        };

        const runWorkflow = () => {
            emit('run');
        };

        const exportingWorkflow = () => {
            emit('exporting');
        };

        const runningEdgeWorkflow = () => {
            emit('edge-run');
        };
        
        

        const finaliseWorkflow = () => {
            if (!R.isNil(props.validationErrors) && props.validationErrors.length === 0) {
                emit('finalise');
            }
        };

        return {
            renamings,
            emit,
            activePage,
            uuidv4,
            openPage,
            toggleDropdown,
            runWorkflow,
            ExecutionType,
            finaliseWorkflow,
            exportingWorkflow,
            runningEdgeWorkflow,
        };
    },
});
