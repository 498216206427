var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row",class:[
            {
                'bg-purple-600 ': _vm.category.colour === 'purple',
                'bg-blue-600  ': _vm.category.colour === 'blue',
                'bg-green-600 ': _vm.category.colour === 'green',
                'bg-orange-600 ': _vm.category.colour === 'orange',
            } ]},[_c('div',{staticClass:"flex flex-col flex-1 px-4 py-6 sm:px-6"},[_c('div',[(!_vm.showEditName)?_c('div',{staticClass:"flex flex-row items-center space-x-2 cursor-pointer",on:{"click":function($event){$event.stopPropagation();_vm.showEditName = true},"mouseenter":function($event){_vm.showEditNameIcon = true},"mouseleave":function($event){_vm.showEditNameIcon = false}}},[_c('h2',{staticClass:"text-lg font-medium text-white truncate w-72",class:{ 'mr-8': !_vm.showEditNameIcon, 'mr-0': !_vm.showEditNameIcon },attrs:{"id":"slide-over-heading"}},[_vm._v(" "+_vm._s(_vm.task.displayName)+" ")]),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEditNameIcon),expression:"showEditNameIcon"}],staticClass:"w-6 h-6",class:[
                            {
                                'text-purple-200 ': _vm.category.colour === 'purple',
                                'text-blue-200  ': _vm.category.colour === 'blue',
                                'text-green-200 ': _vm.category.colour === 'green',
                                'text-orange-200 ': _vm.category.colour === 'orange',
                            } ],attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"}})])]):_c('validation-provider',{attrs:{"rules":"required|max:32","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var invalid = ref.invalid;
                        var valid = ref.valid;
return [_c('div',{staticClass:"relative flex flex-row justify-between w-full"},[_c('tw-input',{staticClass:"w-full",attrs:{"type":"text","name":"name","errors":errors,"fullError":false,"additionalInputClasses":invalid ? 'pr-16' : 'pr-8'},on:{"enter":function($event){_vm.showEditName.value = false},"change":function($event){return _vm.saveDisplayName(valid)}},model:{value:(_vm.newDisplayName),callback:function ($$v) {_vm.newDisplayName=$$v},expression:"newDisplayName"}}),_c('div',{staticClass:"absolute right-0 flex flex-row mt-1 mr-1 space-x-1",class:{ 'mr-8': invalid }},[_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Cancel rename"),expression:"`Cancel rename`"}],staticClass:"w-6 h-6 cursor-pointer text-neutral-500 hover:text-neutral-700",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){$event.stopPropagation();return _vm.resetDisplayName.apply(null, arguments)}}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"}})])])],1)]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('p',{staticClass:"text-sm truncate w-72",class:[
                        {
                            'text-purple-200 ': _vm.category.colour === 'purple',
                            'text-blue-200  ': _vm.category.colour === 'blue',
                            'text-green-200 ': _vm.category.colour === 'green',
                            'text-orange-200 ': _vm.category.colour === 'orange',
                        } ]},[_vm._v(" "+_vm._s(_vm.description)+" ")])])]),(!_vm.isOutput)?_c('div',{staticClass:"flex flex-row items-center flex-initial"},[(_vm.runningExecutionTooltip || _vm.isDeprecated)?_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.runningExecutionTooltip),expression:"runningExecutionTooltip"}],staticClass:"w-10 h-10 mr-4 opacity-50 cursor-not-allowed text-neutral-300",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z","clip-rule":"evenodd"}})]):_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Perform a partial run, up to this task, to get a sample"),expression:"`Perform a partial run, up to this task, to get a sample`"}],staticClass:"w-10 h-10 mr-4 text-white cursor-pointer",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){$event.stopPropagation();return _vm.testRun.apply(null, arguments)}}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z","clip-rule":"evenodd"}})])]):_vm._e()]),_c('div',{staticClass:"flex flex-row p-2 px-4 space-x-2 text-sm",class:[
            {
                'bg-purple-200 ': _vm.category.colour === 'purple',
                'bg-blue-200  ': _vm.category.colour === 'blue',
                'bg-green-200 ': _vm.category.colour === 'green',
                'bg-orange-200 ': _vm.category.colour === 'orange',
            } ]},[_c('v-popover',{staticClass:"inline",attrs:{"trigger":"hover focus","placement":"top","delay":{ show: 500 }}},[_vm._t("default",function(){return [_c('div',{staticClass:"flex flex-row items-center w-full space-x-2 cursor-help"},[(_vm.type)?_c('span',{staticClass:"px-1 bg-white rounded text-neutral-600"},[_vm._v(" "+_vm._s(_vm.type)+" ")]):_vm._e(),_c('span',{staticClass:"font-bold truncate text-neutral-700"},[_vm._v(_vm._s(_vm.task.block.name))]),_c('span',{staticClass:"text-neutral-600"},[_vm._v(_vm._s(_vm.task.block.version))])])]}),_c('div',{staticClass:"\n                    flex-shrink-0\n                    text-white\n                    border\n                    rounded\n                    shadow-lg\n                    w-72\n                    flex-end\n                    bg-neutral-900\n                    border-neutral-900\n                    flex-no-grow\n                ",attrs:{"slot":"popover"},slot:"popover"},[_vm._v(" This task is based on block "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.task.block.name)+" v"+_vm._s(_vm.task.block.version))]),_vm._v(" of category "),_c('span',{staticClass:"font-bold",class:[
                        {
                            'text-purple-600 ': _vm.category.colour === 'purple',
                            'text-blue-600  ': _vm.category.colour === 'blue',
                            'text-green-600 ': _vm.category.colour === 'green',
                            'text-orange-600 ': _vm.category.colour === 'orange',
                        } ]},[_vm._v(_vm._s(_vm.category.label))]),(_vm.type)?_c('span',[_vm._v(" and type "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.type))])]):_vm._e()])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }