













import { computed, defineComponent } from '@vue/composition-api';
import { DocumentDownloadIcon } from "@vue-hero-icons/outline"

export default defineComponent({
    name: 'ExportWorkflowWidget',
    props: {
        disabled: { type: Boolean, default: false },
        runningExecution: {
            type: Object,
            default: null,
        },
        downloading: {
            type: Boolean,
            default: false,
        },
    },
    components: { DocumentDownloadIcon },
    setup(props) {
        const runningExecutionTooltip = computed(() => {
            if (props.downloading) {
                return 'Downloading....';
            }
            if (props.disabled) {
                return 'The workflow has validation errors and cannot be download the models';
            }
            return null;
        });

        return { runningExecutionTooltip };
    },
});
