





























































































































import * as R from 'ramda';
import dayjs from 'dayjs';
import { OrbitSpinner } from 'epic-spinners';
import { AgGridVue } from 'ag-grid-vue';
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { SvgImage, TwButton } from '@/app/components';
import { useTaskTableView } from '../../composable/task-table-view';
import { ExecutionType, ExecutionTypeWrapper, ExecutionStatus, BlockCategory } from '../../constants';
import SampleDescription from './SampleDescription.vue';

export default defineComponent({
    name: 'TableViewTable',
    props: {
        task: {
            type: Object,
            required: true,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
    },
    components: { SvgImage, OrbitSpinner, AgGridVue, TwButton, SampleDescription },
    setup(props, { emit }) {
        const {
            latestNormalExecution,
            latestNormalExecutionSample,
            latestNormalExecutionStructure,
            latestNormalExecutionSampleConfiguration: sampleConfiguration,
            loading,
            refetch,
        } = useTaskTableView(props.task.id);
        const columnAPI = ref<any>(null);
        const gridAPI = ref<any>(null);
        const defaultColumnDefinition = {
            sortable: true,
            resizable: true,
            flex: 1,
            minWidth: 200,
        };
        const lastRunningExecution = ref<any>(null);

        const fields = computed(() => {
            if (latestNormalExecutionStructure.value) {
                return Object.keys(latestNormalExecutionStructure.value).map((field: string) => {
                    return {
                        field,
                        headerName: `${field} (${latestNormalExecutionStructure.value[field]})`,
                        headerTooltip: `${field} (${latestNormalExecutionStructure.value[field]})`,
                        tooltipValueGetter: (cell: any) => {
                            const val = cell.data[cell.colDef.field];
                            if (R.is(Object, val)) {
                                return JSON.stringify(val, null, '\t');
                            }
                            return val;
                        },
                        valueFormatter: (cell: any) => {
                            const val = cell.data[cell.colDef.field];
                            if (R.is(Object, val)) {
                                return JSON.stringify(val, null, '\t');
                            }
                            return val;
                        },
                    };
                });
            }
            return latestNormalExecutionStructure.value;
        });

        const isTaskRunning = computed(
            () =>
                !R.isNil(props.runningExecution) &&
                !R.isNil(props.runningExecution.task) &&
                props.runningExecution.task.id === props.task.id,
        );
        const runningExecutionImage = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                if (props.runningExecution.status === ExecutionStatus.Queued) {
                    return 'queue';
                }
                if (props.runningExecution.type === ExecutionType.Dry) {
                    return 'building';
                }
                return 'calculating';
            }
            return null;
        });

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return 'A run is currently in progress';
        });

        const lastUpdatedDateTime = computed(() => {
            if (!R.isNil(latestNormalExecution.value)) {
                return dayjs(latestNormalExecution.value.updatedAt).format('DD MMMM YYYY HH:MM:ss');
            }
            return null;
        });

        const lastUpdatedShort = computed(() => {
            if (!R.isNil(latestNormalExecution.value)) {
                return dayjs(latestNormalExecution.value.updatedAt).fromNow();
            }
            return null;
        });

        const canRunTestRun = computed(() => props.task.block.category !== BlockCategory.Output);

        const isTraining = computed(
            () => props.task.block.category === BlockCategory.MachineLearning && props.task.block.type === 'train',
        );

        const testRun = () => {
            emit('test-run');
        };

        const onGridReady = (params: { api: any; columnApi: any }) => {
            gridAPI.value = params.api;
            columnAPI.value = params.columnApi;
        };

        watch(
            () => props.runningExecution,
            (execution: any) => {
                if (R.isNil(execution) && execution !== lastRunningExecution.value) {
                    refetch();
                }
                lastRunningExecution.value = execution;
            },
        );

        return {
            fields,
            latestNormalExecutionSample,
            testRun,
            loading,
            onGridReady,
            defaultColumnDefinition,
            runningExecutionImage,
            runningExecutionTooltip,
            isTaskRunning,
            lastUpdatedDateTime,
            lastUpdatedShort,
            canRunTestRun,
            isTraining,
            sampleConfiguration,
        };
    },
});
