


















































import { computed, defineComponent } from '@vue/composition-api';
import { extend } from 'vee-validate';
import { Toggle, TwSelect } from '@/app/components';
import {
    minListLengthValidator,
    maxListLengthValidator,
    requiredValidator,
    minValueValidator,
    maxValueValidator,
    regexValidator,
    excludedValidator,
} from '@/app/validators';
import { useTaskParameterConfiguration } from '../../composable';
import { InputParameter, Workflow } from '../../types';

extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);
extend('required', requiredValidator);
extend('minList', minListLengthValidator);
extend('maxList', maxListLengthValidator);
extend('regex', regexValidator);
extend('excluded', excludedValidator);

export default defineComponent({
    name: 'TaskParameterConfiguration',
    model: {
        prop: 'parameterConfig',
        event: 'update-parameter-configuration',
    },
    props: {
        parameterConfig: {
            type: Object,
            default: () => {
                return { value: null };
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        parameters: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        workflowConfiguration: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forceUpdate: {
            type: Date,
            default: () => {
                return new Date();
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        models: {
            type: Array,
            default: () => [],
        },

        isOnPremise: {
            type: Boolean,
            default: false,
        },
        runnerId: {
            type: String,
            default: null,
        },
        parametersDefinition: {
            type: Object,
            required: true,
        },
    },
    components: { Toggle, TwSelect },
    setup(props, { emit }) {
        // Transforming volitile props into computed properties so that the useTaskParameterConfiguration composable is reactive
        const visible = computed(() => props.visible);
        const models = computed((): any[] => props.models);
        const isOnPremise = computed(() => props.isOnPremise);
        const forceUpdate = computed(() => props.forceUpdate);
        const workflowConfiguration = computed((): Workflow => props.workflowConfiguration as Workflow);
        const parametersDefinition = computed((): Record<string, InputParameter> => props.parametersDefinition);

        const {
            // The parameter name
            name,

            // The rules to validate this parameter against
            rules,

            // Based on the input parameter configuration
            // figure out which of the ParameterComponent
            // options this is
            parameterComponent,

            // Whether this parameter is visible right now or not
            display,
        } = useTaskParameterConfiguration(
            props.parameter as InputParameter,
            workflowConfiguration,
            parametersDefinition,
            models,
            isOnPremise,
            visible,
            forceUpdate,
        );

        const change = (val: any) => {
            emit('update-parameter-configuration', val);
            emit('change', val);
        };

        return { name, rules, display, parameterComponent, change };
    },
});
