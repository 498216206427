import { computed, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { BlockCategory } from '../constants';
import { Pipeline, Task } from '../types';
import { useTasks } from './tasks';

export function useTaskConfiguration(task: Ref<Task>, tasks: Ref<Map<string, Task>>, pipelines: Ref<Pipeline[]>) {
    const tasksComposable = useTasks(tasks);

    const blockCategory = computed(() => tasksComposable.blockCategory(task.value));

    const blockType = computed(() => tasksComposable.blockType(task.value));

    const dataframeParameters = computed(() => tasksComposable.dataframeParameters(task.value));

    const parameters = computed(() => tasksComposable.parameters(task.value));

    const dataframeParameterNames = computed((): string[] => tasksComposable.dataframeParameterNames(task.value));

    const dataframesFromTask = (t: Task) => R.pick(dataframeParameterNames.value, t.configuration);

    const dataframeParametersConfig = ref<any>(dataframesFromTask(task.value));

    const allMachineLearningDataframes = computed(() => {
        const upstream = Object.keys(dataframeParametersConfig.value).reduce((acc: Task[], dataframeKey: string) => {
            const dataframeTaskId = dataframeParametersConfig.value[dataframeKey].task;
            if (dataframeTaskId && tasks.value.has(dataframeTaskId)) {
                const dataframeTask: Task = tasks.value.get(dataframeTaskId) as Task;
                if (
                    dataframeTask.block.category === BlockCategory.MachineLearning &&
                    dataframeTask.block.supportedPipelines.some((sp: string) =>
                        task.value.block.supportedPipelines.includes(sp),
                    )
                ) {
                    acc.push(dataframeTask);
                }
            }
            return acc;
        }, []);
        const downstream = task.value.downstreamTaskIds.reduce((acc: Task[], taskId: string) => {
            if (taskId && tasks.value.has(taskId)) {
                const dataframeTask: Task = tasks.value.get(taskId) as Task;
                if (
                    dataframeTask.block.category === BlockCategory.MachineLearning &&
                    dataframeTask.block.supportedPipelines.some((sp: string) =>
                        task.value.block.supportedPipelines.includes(sp),
                    )
                ) {
                    acc.push(dataframeTask);
                }
            }
            return acc;
        }, []);

        return { downstream, upstream };
    });

    const availableMachineLearningDataframes = computed(() =>
        [...allMachineLearningDataframes.value.downstream, ...allMachineLearningDataframes.value.upstream].reduce(
            (acc: Task[], t: Task) => {
                if (pipelines.value.filter((pl: Pipeline) => pl.tasks.includes(t.id)).length === 0) {
                    acc.push(t);
                }
                return acc;
            },
            [],
        ),
    );

    const availablePipeline = computed((): Pipeline | undefined => {
        return pipelines.value.find(
            (pipeline: Pipeline) =>
                (pipeline.tasks.length > 0 &&
                    task.value.block.supportedPipelines.includes(pipeline.type) &&
                    R.pluck('id', allMachineLearningDataframes.value.upstream).includes(
                        R.last(pipeline.tasks) as string,
                    )) ||
                R.pluck('id', allMachineLearningDataframes.value.downstream).includes(pipeline.tasks[0] as string) ||
                pipeline.tasks.includes(task.value.id),
        );
    });

    const canBeInPipeline = computed(() => {
        return (
            (task.value.block.category === BlockCategory.MachineLearning &&
                availableMachineLearningDataframes.value.length > 0) ||
            !R.isNil(availablePipeline.value)
        );
    });

    const parametersConfig = ref<any>(
        Object.keys(task.value.configuration).reduce((acc: any, key: string) => {
            if (!dataframeParameterNames.value.includes(key)) {
                acc[key] = { ...task.value.configuration[key] };
            }
            return acc;
        }, {}),
    );

    const availableDataframes = computed(() => tasksComposable.availableDataframes(task.value));

    const description = computed(() => tasksComposable.description(task.value));

    const getTask = (taskId: string) => tasksComposable.getTask(taskId);

    return {
        blockCategory,
        blockType,
        dataframeParametersConfig,
        parametersConfig,
        availableDataframes,
        dataframeParameters,
        parameters,
        description,
        getTask,
        canBeInPipeline,
        allMachineLearningDataframes,
        availablePipeline,
        availableMachineLearningDataframes,
    };
}
