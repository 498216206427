




























































































































































































































import { defineComponent, reactive, ref, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { useAxios } from '@vue-composable/axios';
import * as R from 'ramda';
import { OrbitSpinner } from 'epic-spinners';
import { requiredValidator, maxLengthValidator } from '@/app/validators';
import { FormBlock, Scrollbar } from '@/app/components';
import { RunnerAPI } from '@/modules/data-checkin/api';
import { ExecutionFrameworkWrapper, ExecutionFramework } from '../constants';
import { WorkflowAPI } from '../api';
import { renamings} from '@/app/utilities';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'CreateWorkflow',
    components: { ValidationObserver, ValidationProvider, FormBlock, OrbitSpinner, Scrollbar },
    setup(props, { root }) {
        const { exec, loading, error } = useAxios();

        const frameworks = ExecutionFrameworkWrapper.all();

        const workflow = reactive({
            name: '',
            description: '',
            framework: null,
            runnerId: null,
            configuration: { frameworkVersion: null },
        });
        const workflowRef = ref<any>(null);

        const execution = ref<string>('cloud');

        const runners = ref([]);
        // Load registered runners
        exec(RunnerAPI.all()).then((response: any) => {
            if (response && response.data) {
                runners.value = response.data;
            }
        });

        const isWorkflowComplete = computed(() => {
            if (
                !R.isEmpty(workflow.name.trim()) &&
                !R.isEmpty(workflow.description.trim()) &&
                !R.isNil(workflow.framework) &&
                !R.isEmpty(workflow.framework)
            ) {
                if (execution.value === 'cloud') {
                    return true;
                }
                if (workflow.runnerId) {
                    return true;
                }
            }

            return false;
        });

        const selectedFramework = computed(() =>
            workflow.framework ? ExecutionFrameworkWrapper.find(workflow.framework as any) : null,
        );

        const clearExecutionLocation = () => {
            if (workflow.framework === ExecutionFramework.spark3) {
                execution.value = 'cloud';
            }
            workflow.runnerId = null;
        };

        const cancel = () => {
            root.$router.push({ name: 'workflows' });
        };
        const save = async () => {
            const valid = await workflowRef.value.validate();

            if (valid) {
                workflow.configuration.frameworkVersion = selectedFramework.value?.version as any;
                await exec(WorkflowAPI.create(workflow as any)).then((res: any) => {
                    (root as any).$toastr.s(`Workflow '${workflow.name}' has been created`, 'Success');
                    root.$router.push({ name: 'workflow-designer:edit', params: { id: res.data.id } });
                });
            }
        };

        const onCloudExecution = computed(
            () => !workflow.framework || workflow.framework === ExecutionFramework.spark3,
        );
        return {
            isWorkflowComplete,
            workflowRef,
            workflow,
            loading,
            error,
            frameworks,
            cancel,
            save,
            runners,
            execution,
            clearExecutionLocation,
            ExecutionFramework,
            onCloudExecution,
            renamings,
        };
    },
});
