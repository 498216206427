













import { computed, defineComponent } from '@vue/composition-api';
import { PaperAirplaneIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'RunEdgeWidget',
    props: {
        disabled: { type: Boolean, default: false },
        runningEdgeExecution: {
            type: Boolean,
            default: false,
        },
    },
    components: { PaperAirplaneIcon },
    setup(props) {
        const runningExecutionTooltip = computed(() => {
            if (props.runningEdgeExecution) {
                return 'It is trying to trigger the edge service please check the logs on your edge device';
            }
            if (props.disabled) {
                return 'The workflow has validation errors and cannot be ran';
            }
            return null;
        });

        return { runningExecutionTooltip };
    },
});
