import * as R from 'ramda';
import { OtherInputParameterType } from '../../constants';
import { DataType } from '../../constants/data-type.constants';
import { InputParameterCategory } from '../../constants/input-parameter-category.constants';
import {
    ComplexParameter,
    DateTimeParameter,
    DoubleParameter,
    IntegerParameter,
    StringParameter,
    DynamicParameter,
    DynamicColumnParameter,
    LogicalParameter,
    HiddenParameter,
    ModelParameter,
    DatasetAssetParameter,
    ResultAssetParameter,
} from './parameter-components';
import BooleanParameter from './parameter-components/BooleanParameter.vue';
import ColumnParameter from './parameter-components/ColumnParameter.vue';

export class ParameterComponent {
    static readonly INTEGER = new ParameterComponent(
        IntegerParameter,
        InputParameterCategory.Value,
        DataType.Integer,
        false,
        false,
        undefined,
        (val: string) => (val && RegExp(/^-?\d+$/).test(val) ? Number(val) : null),
    );

    static readonly DOUBLE = new ParameterComponent(
        DoubleParameter,
        InputParameterCategory.Value,
        DataType.Double,
        false,
        false,
        undefined,
        (val: string) => (val && RegExp(/^-?\d+\.?\d*$/).test(val) ? Number(val) : null),
    );

    static readonly NUMERIC = new ParameterComponent(
        DoubleParameter,
        InputParameterCategory.Value,
        DataType.Numeric,
        false,
        false,
        undefined,
        (val: string) => (val && RegExp(/^\d+\.?\d*$/).test(val) ? Number(val) : null),
    );

    static readonly STRING = new ParameterComponent(StringParameter, InputParameterCategory.Value, DataType.String);

    static readonly BOOLEAN = new ParameterComponent(
        BooleanParameter,
        InputParameterCategory.Value,
        DataType.Boolean,
        true,
        false,
        undefined,
        (val: string) => val && ((R.is(Boolean, val) && val) || val.trim().toLowerCase() === 'true'),
    );

    static readonly DYNAMIC_COLUMN = new ParameterComponent(
        DynamicColumnParameter,
        InputParameterCategory.Column,
        OtherInputParameterType.Dynamic,
    );

    static readonly COLUMN = new ParameterComponent(ColumnParameter, InputParameterCategory.Column, null);

    static readonly DATETIME = new ParameterComponent(
        DateTimeParameter,
        InputParameterCategory.Value,
        DataType.DateTime,
    );

    static readonly COMPLEX = new ParameterComponent(ComplexParameter, InputParameterCategory.Complex, null);

    static readonly DYNAMIC = new ParameterComponent(
        DynamicParameter,
        InputParameterCategory.Value,
        OtherInputParameterType.Dynamic,
    );

    static readonly LOGICAL = new ParameterComponent(LogicalParameter, InputParameterCategory.Logical, null);

    static readonly HIDDEN = new ParameterComponent(HiddenParameter, InputParameterCategory.Hidden, null, false, true);

    static readonly MODEL = new ParameterComponent(ModelParameter, InputParameterCategory.Model, null);

    static readonly DATASET_ASSET = new ParameterComponent(
        DatasetAssetParameter,
        InputParameterCategory.Asset,
        OtherInputParameterType.Dataset,
        false,
        false,
        'Data Asset',
    );

    static readonly RESULT_ASSET = new ParameterComponent(
        ResultAssetParameter,
        InputParameterCategory.Asset,
        OtherInputParameterType.Result,
        false,
        false,
        'Result Asset',
    );

    private constructor(
        public readonly component: any,
        public readonly category: InputParameterCategory,
        public readonly type: DataType | OtherInputParameterType | null,
        public readonly sameLine = false,
        public readonly alwaysHidden = false,
        public readonly specialLabel?: string | undefined,
        public readonly processDisplayIfValue?: any,
    ) {
        this.component = component;
        this.category = category;
        this.type = type;
        this.sameLine = sameLine;
        this.alwaysHidden = alwaysHidden;
        this.specialLabel = specialLabel;
        this.processDisplayIfValue = processDisplayIfValue;
    }

    static all(): ParameterComponent[] {
        return [
            this.INTEGER,
            this.DOUBLE,
            this.NUMERIC,
            this.STRING,
            this.BOOLEAN,
            this.DYNAMIC_COLUMN,
            this.COLUMN,
            this.DATETIME,
            this.COMPLEX,
            this.DYNAMIC,
            this.LOGICAL,
            this.HIDDEN,
            this.MODEL,
            this.DATASET_ASSET,
            this.RESULT_ASSET,
        ];
    }

    isThis = (category: InputParameterCategory, type: DataType | OtherInputParameterType | null) => {
        return (
            (!this.category || (this.category && this.category === category)) &&
            (!this.type || (this.type && this.type === type))
        );
    };

    is = (component: ParameterComponent) => {
        return (
            (!this.category || (this.category && this.category === component.category)) &&
            (!this.type || (this.type && this.type === component.type))
        );
    };

    toString = () => {
        return `${this.category}_${this.type}`;
    };

    isHidden = () => {
        return this.alwaysHidden;
    };

    processDisplayIf = (v: string) => {
        if (this.processDisplayIfValue) return this.processDisplayIfValue(v);
        return v;
    };

    static find(category: InputParameterCategory, type: DataType | OtherInputParameterType | null): ParameterComponent {
        for (let s = 0; s < ParameterComponent.all().length; s++) {
            const component = ParameterComponent.all()[s];
            if (component.isThis(category, type)) {
                return component;
            }
        }
        throw Error(`Unable to find component with category '${category}' and type '${type}'`);
    }
}
