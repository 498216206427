


















































































































































































































































































import { useAxios } from '@vue-composable/axios';
import * as R from 'ramda';
import { computed, defineComponent, inject, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { HtmlModal, SvgImage } from '@/app/components';
// import { onBeforeRouteLeave } from '@/app/composable/router';
import {
    Configure,
    GraphView,
    ResultsView,
    Schedule,
    TableView,
    TaskConfiguration,
    ValidationSummary,
    WorkflowMenu,
} from '../components';
import { WorkflowAPI } from '../api';
import { Pipeline, Task } from '../types';
import { useWorkflowDesigner } from '../composable/workflow-designer';
import { BlockCategory, BlockOutputType, ExecutionStatusWrapper, ExecutionType } from '../constants';
import { useTasks } from '../composable/tasks';
import { UpdateWorkflowDTO } from '../dto';
import { useVisualisation } from '../composable';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'WorkflowDesigner',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    components: {
        WorkflowMenu,
        OrbitSpinner,
        GraphView,
        TableView,
        ResultsView,
        Configure,
        Schedule,
        TaskConfiguration,
        ValidationSummary,
        HtmlModal,
        SvgImage,
    },
    setup(props, { root }) {
        const renamings = inject('renamings');
        const { exec, loading, error } = useAxios();
        const disableClickEvent = ref(false);
        const editingSchedule = ref<boolean>(false);
        const closeScheduleEditor = ref<boolean>(false);
        const afterDiscardChangesAction = ref<string | null>(null);
        const currentPage = ref('graph-view');
        const currentDropdown = ref<any>(null);
        const taskInConfig = ref<Task | null>(null);
        const schedulesError = ref<string | null>(null);
        const saving = ref<boolean>(false);
        const deleting = ref<boolean>(false);
        const addingTask = ref<boolean>(false);

        const showConfirmFinalise = ref<boolean>(false);

        const taskComposable = useWorkflowDesigner(props.id, (root as any).$toastr);
        const { deleteVisualisation } = useVisualisation();
        const isLoading = computed(() => loading.value || taskComposable.loading.value);

        const {
            workflow,
            pipelines,
            tasks,
            taskMap,
            schedules,
            refetch,
            deleteTask,
            createTask,
            updateTask,
            queueExecution,
            saveSchedule,
            changeScheduleStatus,
            deleteSchedule,
            runningExecution,
            pendingExecutions,
            otherRunningExecutions,
            validationErrors,
            invalidTaskIds,
            finaliseWorkflow,
            unlockWorkflow,
            runValidation,
            models,
            taskVisualisations,
            visualisations,
            isFinalised,
            isDeprecated,
            canBeReopened,
            downloadWorkflow,
            edgeRun,
            runningEdgeExecution,
            downloading,
        } = taskComposable;

        const { columnsPerTask } = useTasks(taskMap);

        const name = computed(() => (workflow.value ? workflow.value.name : ''));

        const isOnPremise = computed(() => (workflow.value ? !R.isNil(workflow.value.runner) : false));
        const runnerId = computed(() => (workflow.value && workflow.value.runner ? workflow.value.runner.id : null));

        const errors = computed(() => {
            const listOfErrors = [];
            if (error.value) {
                listOfErrors.push(error.value);
            }
            return listOfErrors.concat(...taskComposable.errors.value);
        });

        const openPage = (page: string) => {
            currentPage.value = page;
        };

        const toggleDropdown = (dropdown: string) => {
            currentDropdown.value = dropdown === currentDropdown.value ? null : dropdown;
        };

        const back = () => {
            root.$router.go(-1);
        };

        const showSettings = (task: Task | null) => {
            taskInConfig.value = task;
        };

        const showSettingsById = (taskId: string | null) => {
            if (R.isNil(taskId) || !taskMap.value.has(taskId)) {
                taskInConfig.value = null;
            } else {
                showSettings(taskMap.value.get(taskId) as Task);
            }
        };

        const taskChange = async (taskId: string | null = null) => {
            await refetch();
            if (taskId !== null && taskMap.value.has(taskId)) {
                showSettings(taskMap.value.get(taskId) as Task);
            }
        };

        const createSpecificTask = async (taskPayload: {
            displayName: string;
            blockId: string;
            workflowId: string;
            configuration: any;
        }) => {
            addingTask.value = true;
            createTask(taskPayload).then((res: any) => {
                taskChange(res.data.id);
                addingTask.value = false;
            });
        };

        const deleteSpecificTask = async (task: Task) => {
            deleting.value = true;
            if (S.has(task.id, taskVisualisations.value)) {
                await deleteVisualisation(taskVisualisations.value[task.id]);
            }
            deleteTask(task)
                .then(async () => {
                    await taskChange();
                    showSettings(null);
                })
                .finally(() => {
                    deleting.value = false;
                });
        };

        const runTaskTestRun = async (task: Task) => {
            (root as any).$toastr.s(
                `Submitted request to simulate a run up to task <strong>${task.displayName}</strong> and fetching sample data`,
                'Success',
            );
            await queueExecution(ExecutionType.Test, task);
        };

        const runWorkflowNormalRun = async () => {
            if (!R.isNil(workflow.value)) {
                (root as any).$toastr.s(
                    `Submitted request to run workflow <strong>${workflow.value.name}</strong>`,
                    'Success',
                );

                await queueExecution(ExecutionType.Normal);
            }
        };

        const saveWorkflowConfiguration = (configuration: UpdateWorkflowDTO) => {
            exec(WorkflowAPI.update(props.id, configuration))
                .then(async () => {
                    await refetch();
                })
                .catch(() => {
                    (root as any).$toastr.s('Workflow Configuration update has failed due to an error', 'Error');
                });
        };

        const updateWorkflowPipelines = (pls: Pipeline[]) => {
            if (JSON.stringify(pipelines.value) !== JSON.stringify(pls)) {
                saveWorkflowConfiguration({
                    ...workflow.value,
                    configuration: {
                        ...workflow.value.configuration,
                        pipelines: pls,
                    },
                });
            }
        };

        const saveTask = (task: Task, pls: Pipeline[]) => {
            const ogTask = taskMap.value.get(task.id);
            saving.value = true;
            updateTask(task)
                .then(async () => {
                    updateWorkflowPipelines(pls);
                    runningExecution.value = null;
                    await runValidation();
                    if (
                        !invalidTaskIds.value.includes(task.id) &&
                        !R.isNil(ogTask) &&
                        JSON.stringify(ogTask.configuration) !== JSON.stringify(task.configuration) &&
                        task.block.category !== BlockCategory.Output &&
                        task.block.output.type === BlockOutputType.Dynamic &&
                        !(task.block.category === BlockCategory.MachineLearning && task.block.type === 'evaluate')
                    ) {
                        await queueExecution(ExecutionType.Dry, task);
                    }
                    await taskChange();

                    showSettings(null);
                })
                .finally(() => {
                    saving.value = false;
                });
        };

        const handleDropdowns = (dropdown: string) => {
            toggleDropdown(dropdown);
        };

        const toggleClickEvent = (disable: boolean) => {
            disableClickEvent.value = disable;
        };

        // Check if workflow is currently running
        watch(
            () => workflow.value,
            (newWorkflow: any) => {
                if (!R.isNil(newWorkflow)) {
                    if (
                        newWorkflow.executions.length > 0 &&
                        !ExecutionStatusWrapper.finishedStatuses().includes(newWorkflow.executions[0].status)
                    ) {
                        runningExecution.value = {
                            executionId: newWorkflow.executions[0].id,
                            type: newWorkflow.executions[0].type,
                            status: newWorkflow.executions[0].status,
                        };
                    }
                }
            },
        );

        const saveWorkflowSchedule = (schedule: any) => {
            schedulesError.value = null;
            saveSchedule(schedule)
                .then((message: string) => {
                    (root as any).$toastr.s(message, 'Success');
                })
                .catch((errorMessage: string) => {
                    schedulesError.value = errorMessage;
                })
                .finally(() => {
                    closeScheduleEditor.value = true;
                });
        };

        const confirmFinaliseWorkflow = () => {
            showConfirmFinalise.value = true;
        };

        const performFinaliseWorkflow = () => {
            showConfirmFinalise.value = false;
            finaliseWorkflow()
                .then(() => {
                    if (errors.value.length === 0) {
                        (root as any).$toastr.s(`Workflow has been successfully finalised!`, 'Success');
                    } else {
                        (root as any).$toastr.e(errors.value[0], 'Error');
                    }
                })
                .catch((err: { response: { data: { message: string } } }) => {
                    (root as any).$toastr.e(err.response.data.message, 'Error');
                });
        };

        const visualisationChange = () => {
            refetch();
        };

        const retrievedVisualisations = computed(() => {
            if (visualisations.value) {
                return visualisations.value.map((visualisation: any) => {
                    return {
                        id: visualisation.id,
                        title: visualisation.title,
                        subtitle: visualisation.subtitle,
                        type: visualisation.type,
                        workflowId: props.id,
                        taskId: visualisation.task.id,
                        configuration: visualisation.configuration,
                        assetId: visualisation.assetId,
                        retrieval: visualisation.retrieval,
                    };
                });
            }
            return [];
        });

        // onBeforeRouteLeave((to: any, from: any, next: any) => {
        //     if (!disableClickEvent.value) {
        //         next();
        //     }
        // });

        return {
            renamings,
            tasks,
            taskMap,
            isLoading,
            errors,
            workflow,
            name,
            openPage,
            currentPage,
            toggleDropdown,
            currentDropdown,
            back,
            showSettings,
            taskInConfig,
            createSpecificTask,
            taskChange,
            deleteSpecificTask,
            saveTask,
            runWorkflowNormalRun,
            schedules,
            saveWorkflowSchedule,
            changeScheduleStatus,
            deleteSchedule,
            columnsPerTask,
            error,
            schedulesError,
            closeScheduleEditor,
            handleDropdowns,
            afterDiscardChangesAction,
            editingSchedule,
            saveWorkflowConfiguration,
            disableClickEvent,
            toggleClickEvent,
            runTaskTestRun,
            runningExecution,
            pendingExecutions,
            otherRunningExecutions,
            validationErrors,
            showSettingsById,
            invalidTaskIds,
            confirmFinaliseWorkflow,
            isFinalised,
            isDeprecated,
            showConfirmFinalise,
            performFinaliseWorkflow,
            pipelines,
            models,
            isOnPremise,
            runnerId,
            visualisationChange,
            retrievedVisualisations,
            saving,
            deleting,
            addingTask,
            unlockWorkflow,
            canBeReopened,
            downloadWorkflow,
            edgeRun,
            runningEdgeExecution,
            downloading,
        };
    },
});
