


















import { defineComponent, watch, ref, computed } from '@vue/composition-api';
import * as R from 'ramda';
import { DynamicOn } from '@/modules/workflow-designer/constants';
import { ParameterComponent } from '../parameter-component.constants';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'DynamicParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameters: {
            type: Object,
            required: true,
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        workflowConfiguration: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forceUpdate: {
            type: Date,
            default: () => new Date(),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const currentValue = ref<any>(props.value);

        const dynamicOnStructure = computed(() => {
            if (
                props.forceUpdate &&
                S.has(props.parameter.validation.dynamic, props.workflowConfiguration) &&
                S.has('ref', props.workflowConfiguration[props.parameter.validation.dynamic]) &&
                S.has(props.workflowConfiguration[props.parameter.validation.dynamic].ref, props.dataframes) &&
                S.has('task', props.dataframes[props.workflowConfiguration[props.parameter.validation.dynamic].ref]) &&
                S.has(
                    props.dataframes[props.workflowConfiguration[props.parameter.validation.dynamic].ref].task,
                    props.columnsPerTask,
                )
            ) {
                return props.columnsPerTask[
                    props.dataframes[props.workflowConfiguration[props.parameter.validation.dynamic].ref].task
                ];
            }
            return null;
        });

        // Calculates the values the depending block takes
        const parameterValues = computed(() => {
            if (
                props.forceUpdate &&
                !R.isNil(props.parameter.validation.values) &&
                !R.isEmpty(props.parameter.validation.values) &&
                S.has(props.parameter.validation.dynamic, props.workflowConfiguration) &&
                S.has('value', props.workflowConfiguration[props.parameter.validation.dynamic]) &&
                S.has('dynamicOn', props.parameter.validation)
            ) {
                let valueToCheck: any = null;
                if (props.parameter.validation.dynamicOn === DynamicOn.Value) {
                    valueToCheck = props.workflowConfiguration[props.parameter.validation.dynamic].value;
                }
                if (props.parameter.validation.dynamicOn === DynamicOn.Type) {
                    valueToCheck = R.is(Array, props.workflowConfiguration[props.parameter.validation.dynamic].value)
                        ? props.workflowConfiguration[props.parameter.validation.dynamic].value.reduce(
                              (acc: string[], param: string) => {
                                  if (!acc.includes(dynamicOnStructure.value[param])) {
                                      acc.push(dynamicOnStructure.value[param]);
                                  }
                                  return acc;
                              },
                              [],
                          )
                        : dynamicOnStructure.value[
                              props.workflowConfiguration[props.parameter.validation.dynamic].value
                          ];
                }

                if (!R.isNil(valueToCheck)) {
                    return props.parameter.validation.values.reduce(
                        (
                            acc: { value: string; text: string }[],
                            param: { value: string; text?: string; on: string[] },
                        ) => {
                            // When to add parameter:
                            // 1. param.on is not defined (should be included always)
                            // 2. when param.on is defined and valueToCheck is an array and we have a match
                            // 3. when it's not an array and we have a match
                            if (
                                R.isNil(param.on) ||
                                (R.is(Array, valueToCheck) &&
                                    param.on.filter((p: string) => valueToCheck.includes(p)).length > 0) ||
                                (!R.is(Array, valueToCheck) && param.on.includes(valueToCheck))
                            ) {
                                acc.push({
                                    value: param.value,
                                    text: !R.isNil(param.text) && !R.isEmpty(param.text) ? param.text : param.value,
                                });
                            }
                            return acc;
                        },
                        [],
                    );
                }
            }

            return [];
        });

        // Calculates column types to include (if list is empty then include all)
        const parameterComponent = computed(() => {
            if (parameterValues.value.length === 0 && !R.isNil(dynamicOnStructure.value)) {
                const columnName = props.workflowConfiguration[props.parameter.validation.dynamic].value;
                if (S.has(columnName, dynamicOnStructure.value)) {
                    return ParameterComponent.find(props.parameter.category, dynamicOnStructure.value[columnName]);
                }
            }

            return ParameterComponent.STRING;
        });

        const dynamicParameter = computed(() => {
            if (parameterValues.value.length > 0) {
                return {
                    ...props.parameter,
                    validation: {
                        ...(props.parameter.validation ? props.parameter.validation : {}),
                        values: parameterValues.value,
                    },
                };
            }
            return props.parameter;
        });

        const dynamicComponentKey = computed(
            () => `${parameterComponent.value.toString()}_${JSON.stringify(dynamicParameter.value)}`,
        );

        // Triggered when there is a change in the value
        const change = (updatedValue: any) => {
            emit('change', updatedValue);
        };

        // Watch changes in the incoming value to adapt the currently held value
        watch(
            () => props.value,
            (newValue: any) => {
                currentValue.value = newValue;
            },
        );

        return { currentValue, change, parameterComponent, parameterValues, dynamicParameter, dynamicComponentKey };
    },
});
