






































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { TwInput } from '@/app/components';
import { BlockCategory, ExecutionTypeWrapper } from '../../constants';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'TaskConfigurationHeader',
    model: {
        prop: 'displayName',
        event: 'update-display-name',
    },
    props: {
        displayName: {
            type: String,
            required: true,
        },
        originalDisplayName: {
            type: String,
            required: true,
        },
        description: { type: String },
        task: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        isValid: {
            type: Boolean,
            required: true,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
    },
    components: { ValidationProvider, TwInput },
    setup(props, { emit }) {
        const showEditNameIcon = ref<boolean>(false);
        const showEditName = ref<boolean>(false);
        const newDisplayName = ref<string>(props.displayName);

        const runningExecutionTooltip = computed(() => {
            if (!props.isValid) {
                return 'Task configuration is not valid';
            }
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return null;
        });

        const isOutput = computed(() => props.task.block.category === BlockCategory.Output);

        const saveDisplayName = (valid = true) => {
            newDisplayName.value = newDisplayName.value.trim();
            emit('update-display-name', newDisplayName.value);
            emit('name-is-valid', valid);
        };

        const resetDisplayName = () => {
            newDisplayName.value = props.originalDisplayName;
            emit('update-display-name', props.originalDisplayName);
            emit('name-is-valid', true);
            showEditName.value = false;
        };

        const testRun = () => {
            emit('test-run');
        };

        return {
            showEditNameIcon,
            showEditName,
            newDisplayName,
            saveDisplayName,
            resetDisplayName,
            testRun,
            runningExecutionTooltip,
            isOutput,
        };
    },
});
